import Document from '@tiptap/extension-document';
import Link from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { generateHTML } from '@tiptap/html';
import { JSONContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { AccountResponse } from '@bloom/codegen/models/AccountResponse';
import { ContactResponse } from '@bloom/codegen/models/ContactResponse';
import { GalleryResponse } from '@bloom/codegen/models/GalleryResponse';
import { LocaleSettingResponse } from '@bloom/codegen/models/LocaleSettingResponse';
import { MinimalProjectResponse } from '@bloom/codegen/models/MinimalProjectResponse';
import { ProjectResponse } from '@bloom/codegen/models/ProjectResponse';
import { PublicInvoiceResponse } from '@bloom/codegen/models/PublicInvoiceResponse';
import { SigneeResponse } from '@bloom/codegen/models/SigneeResponse';
import { UserResponse } from '@bloom/codegen/models/UserResponse';

import { AvatarNode } from '@bloom/library/components/Tiptap/Extensions/AvatarNode';
import { LogoNode } from '@bloom/library/components/Tiptap/Extensions/LogoNode';
import { VariableNode } from '@bloom/library/components/Tiptap/Extensions/VariableNode';

import { convertDraftJsToTiptap } from './utils';

export type TransformEntityOptions = {
  account?: AccountResponse | null;
  client?: ContactResponse | SigneeResponse | null;
  gallery?: GalleryResponse | null;
  invoice?: PublicInvoiceResponse;
  localeSettings?: LocaleSettingResponse;
  project?: ProjectResponse | MinimalProjectResponse | null;
  referralLink?: string;
  textAlign?: string;
  user?: UserResponse | null;
  // variableAsToken: use the flag to show variables tokens rather than actual value
  variableAsToken?: boolean;
};

function removePWrapperInLi(htmlString: string) {
  return htmlString.replace(/<li([^>]*)>\s*<p([^>]*)>(.*?)<\/p>\s*<\/li>/gi, '<li$1>$3</li>');
}

export const BulletListStyle =
  'list-style-type:disc;margin-top:1rem;margin-bottom:1rem;padding-left:1rem;';
export const OrderedListStyle = 'list-style:decimal;padding-left:1.5rem;';
export const LinkStyle = 'text-decoration:underline';

export const renderTiptapToHtml = (
  contentState: JSONContent | string | null,
  options: TransformEntityOptions = {},
  type: 'single-line' | 'multi-line' = 'multi-line'
) => {
  if (!contentState) {
    return '';
  }

  if (typeof contentState === 'string') {
    contentState = convertDraftJsToTiptap(contentState);

    if (!contentState) {
      return '';
    }
  }

  return (
    removePWrapperInLi(
      generateHTML(
        contentState,
        type === 'multi-line'
          ? [
              StarterKit.configure({
                bulletList: { HTMLAttributes: { style: BulletListStyle } },
                code: false,
                orderedList: { HTMLAttributes: { style: OrderedListStyle } },
                paragraph: { HTMLAttributes: { style: 'margin:0' } },
              }),
              Underline,
              Link.configure({
                HTMLAttributes: { class: 'cursor-pointer underline hover:no-underline' },
                openOnClick: false,
              }),
              TextAlign.configure({ types: ['heading', 'paragraph'] }),
              VariableNode.configure({ mode: 'html', variablesData: options }),
              AvatarNode.configure({ src: options.user?.avatar || '' }),
              LogoNode.configure({ src: options.account?.logo || '' }),
            ]
          : [
              Document.extend({ content: 'text*' }),
              Paragraph,
              Text,
              VariableNode.configure({ mode: 'html', variablesData: options }),
            ]
      )
    )
      .replaceAll('\n', '<br/>')
      // replace empty p tags with br tags
      .replace(/<p[^>]*><\/p>/gi, '<br/>')
  );
};
