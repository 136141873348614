import { mergeAttributes, Node, ReactNodeViewRenderer } from '@tiptap/react';

import { AvatarNodeWrapper } from './Component';

import './AvatarNode.css';

interface AvatarNodeProps {
  src: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    avatar: {
      setAvatar: (options: AvatarNodeProps) => ReturnType;
    };
  }
}

const AvatarNode = Node.create<{
  HTMLAttributes: Record<string, any>;
  src: string;
}>({
  addAttributes() {
    return { src: { default: '' } };
  },

  addCommands() {
    return {
      setAvatar:
        (options) =>
        ({ commands }) =>
          commands.insertContent({ attrs: options, type: this.name }),
    };
  },

  addNodeView:
    typeof window === 'undefined'
      ? undefined
      : () => ReactNodeViewRenderer(AvatarNodeWrapper, { className: '' }),

  addOptions() {
    return {
      HTMLAttributes: {},
      src: '',
    };
  },

  atom: true,
  group: 'block',
  inline: false,

  name: 'avatar',

  parseHTML() {
    return [{ tag: 'div[data-type="avatar"]' }];
  },

  renderHTML({ HTMLAttributes, ...rest }) {
    const src = this.options.src || rest.node.attrs.src;

    return [
      'div',
      mergeAttributes({
        'data-type': 'avatar',
        src,
        style: `background-image:url(${src});background-position:center;background-size:cover;border-radius:50%;height:40px;margin-bottom:10px;width:40px;`,
      }),
    ];
  },
});

export { AvatarNode };
