import { mergeAttributes, Node, ReactNodeViewRenderer } from '@tiptap/react';

import './LogoNode.css';
import { LogoNodeWrapper } from './Component';

interface LogoNodeProps {
  src: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    logo: {
      setLogo: (options: LogoNodeProps) => ReturnType;
    };
  }
}

const LogoNode = Node.create<{
  HTMLAttributes: Record<string, any>;
  src: string;
}>({
  addAttributes() {
    return {
      src: { default: '' },
    };
  },

  addCommands() {
    return {
      setLogo:
        (options) =>
        ({ commands }) =>
          commands.insertContent({ attrs: options, type: this.name }),
    };
  },

  addNodeView:
    typeof window === 'undefined'
      ? undefined
      : () => ReactNodeViewRenderer(LogoNodeWrapper, { className: '' }),

  addOptions() {
    return {
      HTMLAttributes: {},
      src: '',
    };
  },

  atom: true,
  group: 'block',
  inline: false,

  name: 'logo',

  parseHTML() {
    return [{ tag: 'div[data-type="logo"]' }];
  },

  renderHTML({ HTMLAttributes, ...rest }) {
    const src = this.options.src || rest.node.attrs.src;

    return [
      'img',
      mergeAttributes({
        'data-type': 'logo',
        src,
        style: 'display:block;pointer-events:none;max-width:250px;max-height:60px;',
      }),
    ];
  },
});

export { LogoNode };
